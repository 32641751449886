<template>
  <b-modal
    ref="duplicateemail"
    id="modal-center"
    centered
    title="Please enter unique email"
    ok-only
    ok-title="Ok"
    @ok="handleOk"
  >
    <b-card-text>
      Looks like the entered email is already in use. Please enter a new email.
    </b-card-text>
  </b-modal>
</template>

<script>
import { BModal, BCardText } from "bootstrap-vue";
export default {
  components: {
    BModal,
    BCardText,
  },
//   directives: {
//     "b-modal": VBModal,
//   },
  methods: {
    dummy() {
        this.$refs.duplicateemail.show();
    },
    handleOk(){
      this.$router.push("register-demo");
    }
  }
};
</script>

<style></style>