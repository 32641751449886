<template>
  <b-modal
    ref="checkemail"
    id="modal-center"
    centered
    title="Confirm your account"
    ok-only
    ok-title="Ok"
  >
    <b-card-text>
      Please check your email inbox and activate your account.
    </b-card-text>
  </b-modal>
</template>

<script>
import { BModal, BCardText } from "bootstrap-vue";
export default {
  components: {
    BModal,
    BCardText,
  },
//   directives: {
//     "b-modal": VBModal,
//   },
  methods: {
    dummy() {
        this.$refs.checkemail.show();
    }
  }
};
</script>

<style></style>