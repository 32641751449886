<template>
  <div>
  <Navbar/>
  <div class="">
    <b-row class=""
    >
      <!-- Brand logo-->
      
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="6"
        class="d-none d-lg-flex align-items-center"
        
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5 prime-blue-background height-90"
        >
          <b-img
            fluid
            src="../../assets/yicimages/register/HumaaansWireframe2.png"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        v-if="!registerDone"
        lg="6"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-text
            title-tag="h2"
            class="font-weight-bold mb-1"
            style="font-size: large; color: black"
          >
            Create Password
          </b-card-text>

          <!-- form -->
          <validation-observer ref="passwordForm">
            <b-form class="auth-register-form mt-2">
              <b-form-group label-for="register-password">
                <password v-model="password" :toggle="true" required />
              </b-form-group>
              <b-button
                variant="primary"
                size="lg"
                type="submit"
                @click.prevent="validationForm"
              >
                Next
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <b-col
        v-if="registerDone"
        lg="6"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Great ! User registered successfully !
          </b-card-title>
          <b-button
            class="bttn1"
            variant="no-color"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            @click="$router.push('/loginv2')"
          >
            LOGIN
          </b-button>
        </b-col>
      </b-col>
    </b-row>
    <!-- Virtual address containing Popup! -->
    <div v-if="modalShow">
      <RegisterVirtualAddress :open="modalShow" />
    </div>
    <checkemail ref="checkmail"></checkemail>
    <duplicateemail ref="duplicateemail"></duplicateemail>
  </div>
</div>
</template>

<script>
import Navbar from '../navbar/Navbar.vue'
import RegisterVirtualAddress from "./RegisterVirtualAddress.vue";
import checkemail from "./checkemailmodal.vue";
import duplicateemail from "./enteruniqueemailmodal.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import { required, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Password from "vue-password-strength-meter";
// import Captcha from "./Captcha.vue";

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    Navbar,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    Password,
    RegisterVirtualAddress,
    checkemail,
    duplicateemail,
  },

  data() {
    return {
      password: "",
      // modalShow: true,
      modalShow: false,
      registerDone: false,
      // isCaptchaVerified: false,
      UE: false,
    };
  },
  computed: {
    ...mapState({
      userAlreadyExists: (state) => {
        return state.register.userAlreadyExists;
      },
    }),
  },
  methods: {
    ...mapMutations({ setPassword: "register/SET_PASSWORD" }),
    ...mapActions({ registerUser: "register/registerUser" }),
    validationForm() {
      // console.log(this.isCaptchaVerified);
      // if (!this.isCaptchaVerified) return;
      this.$refs.passwordForm.validate().then(async (success) => {
        if (success) {
          this.setPassword(this.password);
          try {
            await this.registerUser();
            if (this.userAlreadyExists) {
              console.log("User already exists",this.userAlreadyExists);
              this.$refs.duplicateemail.dummy();
            } else {
              console.log("New User",this.userAlreadyExists);
              this.$refs.checkmail.dummy();
              this.registerDone = true;
            }
          } catch (err) {
            console.error(err);
          }
        }
      });
    },
  },
};
/* eslint-disable global-require */
</script>
<style>
.prime-blue-background {
  background-color: #4385f5;
}
.height-90 {
  height: 85vh;
}
.bttn1 {
  background: #e87613;
  color: #ffffff;
  width: 185px;
  height: 40px;
}
select option {
  margin: 40px;
  background-color: white;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}
</style>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
<style scoped>
.captcha {
  margin-bottom: 1rem;
  /* padding-left: 1rem; */
}
</style>
